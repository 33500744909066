@tailwind base;

@layer base {
  h1 {
    @apply text-3xl font-bold text-gray-900;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }

  table {
    @apply min-w-full divide-y divide-gray-200;
  }

  thead {
    @apply bg-gray-100;
  }

  th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }

  tbody {
    @apply bg-white divide-y divide-gray-200;
  }

  td {
    @apply px-6 py-4 whitespace-nowrap;
  }

  nav {
    @apply bg-gray-800 max-w-80 mx-auto px-2 sm:px-6 lg:px-8 relative flex items-center justify-between h-16;
  }

  nav ul {
    @apply flex space-x-4;
  }

  nav ul a {
    @apply text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium;
  }

  header {
    @apply bg-white shadow max-w-80 mx-auto py-6 px-4 sm:px-6 lg:px-8;
  }

  body {
    @apply bg-gray-50;
  }

  pre {
    @apply bg-gray-200 p-2 rounded-md border border-gray-400 my-3 shadow-sm font-medium text-sm;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }
}

@tailwind components;

@layer components {
  nav ul a.is-active {
    @apply bg-gray-700;
  }

  .content {
    @apply max-w-80 mx-auto py-6;
  }

  .content p {
    @apply font-normal mb-6 mx-3 px-2;
  }

  .content ul {
    @apply list-disc list-inside font-normal mb-6 mx-3 px-5;
  }

  .content .link {
    @apply px-6 py-4 whitespace-nowrap text-right text-sm;
  }

  .content a {
    @apply text-indigo-600 hover:text-indigo-900 font-medium;
  }

  .missing-date {
    @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800;
  }

  .default-date {
    @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-300 text-gray-800;
  }

  .ok-date {
    @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800;
  }

  .warn-date {
    @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-600;
  }

  button.navigation {
    @apply bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500;
  }

  .typebox {
    @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
  }

  .submit {
    @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white disabled:opacity-50 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }

  .reset {
    @apply inline-flex justify-center ml-5 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 disabled:opacity-50  hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }

  .cancel-approval {
    @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
  }

  .submit-approval {
    @apply w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm;
  }

  .dialog-approval {
    @apply inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full;
  }

  .dialog {
    @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .typebox-small {
    @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-24 shadow-sm sm:text-sm border-gray-300 rounded-md;
  }

  .typebox-large {
    @apply box-content h-32 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
  }

  .leadcrawler-input {
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mb-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200  focus:border-gray-500 focus:bg-white focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400;
  }
  .leadcrawler-startbutton {
    @apply flex items-center px-5 py-2.5 font-medium tracking-wide text-white capitalize bg-black rounded-md hover:bg-gray-800  focus:outline-none focus:bg-gray-900  transition duration-300 transform ease-in-out
  }
}

@tailwind utilities;
